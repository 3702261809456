<template>
  <div>
    <a-form-item
      :wrapper-col="{ span: 24 }"
    >
      <a-radio-group
        v-decorator="['card_type',{ initialValue: cardType }]"
        @change="handleCardTypeChange"
      >
        <a-radio :value="0">
          ICCID
        </a-radio>
        <a-radio :value="1">
          融合卡号(SIMID)
        </a-radio>
      </a-radio-group>
    </a-form-item>

    <a-form-item
      :wrapper-col="{ span: 24 }"
    >
      <a-textarea
        :placeholder="placeholder"
        :rows="rows"
        :style="{ color: this.validateStatus === 'error' ? 'red' : '' }"
        v-model.trim="inputValue"
        @blur="handleCards"
      />
    </a-form-item>

    <a-form-item label="卡号总数" :style="{ display: 'inline-block', width: '50%' }">
      <span class="cards-count">{{ cardsCount }}</span>
    </a-form-item>

    <a-form-item label="重复数量" :style="{ display: 'inline-block', width: '50%' }">
      <span class="cards-count">{{ duplicateCardsCount }}</span>
    </a-form-item>
  </div>
</template>

<script>
import { analyzeCards, maxCardLines } from '@/utils/card'

export default {
  name: 'CommonCardInput',
  props: {
    rows: {
      type: Number,
      default: 10
    },
    /**
     * value 结构:
     * {
          inputValue: '', // 输入框内容
          data: []        // iccid数组
        }
     */
    // 如果传inputValue，则自动填充到输入框
    // 如果inputValue为空，data中有值，将data中的值填充到输入框
    // https://github.com/vueComponent/ant-design-vue/issues/915
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: Object
    },
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    const value = this.value || {}

    // 如果inputValue为空，data中有值，将data中的值填充到输入框
    if (!value.inputValue && Array.isArray(value.data) && value.data.length > 0) {
      value.inputValue = value.data.join('\n')
    }

    return {
      inValue: {},
      cardType: this.type, // 输入类型：0:ICCID，1:融合卡号

      placeholder: '',
      placeholderIccid: '可以直接复制excel中整列ICCID；\n' +
        '手动输入多个ICCID，一行一个；\n' +
        '连号的ICCID只需要输入最后几位不一样的即可，用波浪线连接。\n' +
        '例如: 89860451092270150000~100',
      placeholderMixNumber: '可以直接复制excel中整列SIMID；\n' +
        '手动输入多个SIMID，一行一个；\n' +
        '连号的SIMID只需要输入最后几位不一样的即可，用波浪线连接。\n' +
        '例如: 513107000~100',
      cardsCount: 0,
      duplicateCardsCount: 0,
      inputValue: value.inputValue || '', // 输入框内容
      data: value.data || [], // iccid数组
      validateStatus: 'success',
      errorMsg: ''
    }
  },
  watch: {
    value(val = {}) {
      this.inputValue = val.inputValue
      this.data = val.data
    }
  },
  created() {
    this.placeholder = this.placeholderIccid

    if (this.value) {
      this.inValue = this.value
    }

    if (this.inputValue) {
      this.handleCards()
    }
  },
  methods: {
    initData() {
      if (this.inValue) {
        this.inputValue = this.inValue.inputValue
        this.data = this.inValue.data
      }
    },

    handleCardTypeChange(e) {
      if (e.target.value === 0) {
        this.cardType = 0
        this.placeholder = this.placeholderIccid
      } else if (e.target.value === 1) {
        this.cardType = 1
        this.placeholder = this.placeholderMixNumber
      }

      // 切换卡类型重置数据
      this.initData()

      this.handleCards()
    },

    triggerChange() {
      // Should provide an event to pass value to Form.
      this.$emit('change', Object.assign({}, this.$data))
    },

    handleCards() {
      this.validateCards()
      this.triggerChange()
    },

    validateCards() {
      // 未输入内容
      if (!this.inputValue) {
        this.validateStatus = 'error'
        this.errorMsg = '请输入号码'
        return
      }

      // 处理行
      const { lines, validCards, errorText } = analyzeCards(this.cardType, this.inputValue)

      // 超过10万行不处理
      if (lines > maxCardLines) {
        this.validateStatus = 'error'
        this.errorMsg = `卡数量不能超过${maxCardLines}`
        return
      }

      // 处理错误
      if (errorText) {
        this.validateStatus = 'error'
        this.errorMsg = '号码包含错误，无法提交'
      } else {
        this.validateStatus = 'success'
        this.errorMsg = null
      }

      // 拼接 textarea 内容(错误的放到最上面)
      this.inputValue = errorText + validCards.join('\n')
      this.cardsCount = validCards.length
      // 设置有效的iccid
      this.data = this.$lodash.uniq(validCards)
      this.duplicateCardsCount = validCards.length - this.data.length
    }
  }
}
</script>

<style lang="less" scoped>
.cards-count {
  padding-left: 20px;
}
</style>
